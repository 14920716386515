import acpPaywallEsignTemplate from './templates/acp-paywall-esign.html';
// @ngInject
function acpPaywallEsignDirective() {
  return {
    restrict: 'E',
    scope: {
      defer: '=',
      type: '@',
      esignDescription: '='
    },
    controller: 'AcpEsignPaywallComponentCtrl',
    template: acpPaywallEsignTemplate
  };
}

export default acpPaywallEsignDirective;
