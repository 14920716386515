import paywallEsignContent from './paywall-esign-content.yml';
import acpPaywallEsignCtrl from './acp-paywall-esign-ctrl';
import acpPaywallEsignDirective from './acp-paywall-esign-directive';
import acpComponentEsign from 'components/esign';
import acpStopTouchEventDirective from 'shared/angularjs/directives/stop-touch-event';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
import acpUnsafeHtmlFilter from 'shared/angularjs/filters/acp-unsafe-html';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall.esign
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-esign></acp-paywall-esign>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywall-esign.example', [
 *   require('acp.component.paywall-esign').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.paywall-esign', [
    acpCore.name,
    acpUnsafeHtmlFilter.name,
    acpComponentEsign.name,
    acpStopTouchEventDirective.name
  ])
  .directive('acpPaywallEsign', acpPaywallEsignDirective)
  .controller('AcpEsignPaywallComponentCtrl', acpPaywallEsignCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/paywall-esign', paywallEsignContent);
    }
  );

export default component;
