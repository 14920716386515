// @ngInject
function AcpEsignPaywallComponentCtrl(
  $scope,
  acpCoreDispatcher,
  nsUtil,
  acpCardownerDomainModel,
  acpTermsModel,
  acpUserModel
) {
  var type = 'esign';
  function init() {
    $scope.error = false;
    $scope.busy = false;
    $scope.esign = {
      accepted: false
    };
    acpTermsModel.getEsign().then(onEsign);
    acpUserModel.get();
    $scope.$onValue(acpCoreDispatcher.profile.user.updated, onAccountUpdate);
  }
  function onEsign(data) {
    $scope.accepted = data.accepted;
  }
  $scope.close = function() {
    $scope.defer.reject({ error: false, wall: 'acp-esign-paywall' });
  };
  function done() {
    $scope.busy = false;
  }
  $scope.save = function(accepted) {
    if (!$scope.canSubmit()) {
      return;
    }
    acpCoreDispatcher.scrollTo.top.emit();
    $scope.busy = true;
    if (accepted === true) {
      if ($scope.esignForm.email.$dirty) {
        acpCardownerDomainModel
          .edit($scope.account.primary_card_owner.id, {
            email: $scope.esign.email
          })
          .then(onProfileEditSuccess, onProfileEditError);
      } else {
        acpTermsModel.acceptTerms(type);
      }
    } else {
      acpTermsModel.declineTerms(type);
    }
  };
  function onProfileEditSuccess() {
    acpTermsModel.acceptTerms(type);
  }
  function onProfileEditError(response) {
    if (nsUtil.isDefined(response.error)) {
      $scope.serverErrors = response._server_errors;
      $scope.fieldErrors = nsUtil.getFieldErrors(response);
    }
    done();
  }
  function onAccountUpdate(account) {
    $scope.account = account;
    $scope.esign.email = account.primary_card_owner.email;
    $scope.esign._email = $scope.esign.email;
  }

  $scope.canSubmit = function() {
    return $scope.esign.email && $scope.esign.accepted;
  };

  function onTermsAccepted(response) {
    if (response.type === type) {
      $scope.defer.resolve({ error: false, wall: 'acp-esign-paywall' });
    }
    done();
  }

  function onTermsAcceptedFailed(response) {
    if (response.type === type) {
      return;
    }
    done();
  }

  init();
  $scope.$onValue(acpCoreDispatcher.terms.accepted.success, onTermsAccepted);
  $scope.$onValue(
    acpCoreDispatcher.terms.accepted.error,
    onTermsAcceptedFailed
  );
}

export default AcpEsignPaywallComponentCtrl;
